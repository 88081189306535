<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 240 400"
  ><path
    fill="currentColor"
    fill-rule="evenodd"
    d="M208 0h-16c-18 0-32 14-32 32v96c0 18-14 32-32 32h-16c-18 0-32-14-32-32V32C80 14 66 0 48 0H32C14 0 0 14 0 32v176c0 18 14 32 32 32h96c18 0 32 14 32 32v96c0 18 14 32 32 32h16c18 0 32-14 32-32v-96c0-18-14-32-32-32h-16c-18 0-32-14-32-32v-16c0-18 14-32 32-32h16c18 0 32-14 32-32V32c0-18-14-32-32-32Z"
    clip-rule="evenodd"
  /></svg>
</template>
